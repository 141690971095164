@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.contact {
  padding: 40px 0;
  margin: 30px 0;
}

.contact .contact__title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.contact__content {
  padding: 30px 0;
}

.contact__list {
  width: 100%;
  height: 100%;
}

.contact__list .contact__item {
  list-style: none;
  margin-bottom: 20px;
  padding: 5px 0;
}

.item__link {
  text-align: center;
  display: block;
}

.item__icon {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #4b7be5;
}

.item__text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
}
