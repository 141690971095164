@mixin sectionPM {
  padding: 40px 0;
  margin: 30px 0;
}
@mixin main_title($color) {
  text-align: center;
  font-size: 50px;
  font-family: $main_font;
  color: $color;
  margin-bottom: 40px;
}
@mixin flexcolcenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 
@mixin flexrowcenter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}