@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.sponsors {
  padding: 40px 0;
  margin: 30px 0;
  background-color: #fbfbfb;
}

.contact__title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: "#fff";
  margin-bottom: 40px;
}

.sponsors__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sponsors__item {
  padding: 5px;
  margin: 5px;
  list-style: none;
}

.sponsors__title {
  font-size: 17px;
  color: #000;
}
