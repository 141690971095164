@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.blog {
  padding: 40px 0;
  margin: 30px 0;
}

.blog .blog_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.blog_item {
  padding: 20px 0;
  width: 100%;
  border-bottom: 2px solid #eee;
}

.blog_item .blog_item_img_area {
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.blog_item .blog_item_img_area .blog_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blog_item .blog_item_img_area .blog_img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.blog_item_info {
  padding: 15px 0;
}

.blog_item_info .blog_icon {
  color: #aaa;
  margin-right: 5px;
}

.blog_item_info .blog_item_info_author {
  font-family: "Source Sans Pro", sans-serif;
  color: #999;
}

.blog_item_content {
  padding: 10px 0;
  width: 100%;
}

.blog_item_title {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  color: #000;
}

.blog_item_text {
  font-family: "Source Sans Pro", sans-serif;
  color: #888;
  font-size: 17px;
}

.blog_item_footer {
  margin-top: 10px;
  width: 100%;
}

.blog_item_desc_area {
  position: relative;
  z-index: 0;
}

.blog_item_fade {
  background: -webkit-gradient(linear, left bottom, left top, from(white), to(rgba(230, 126, 94, 0)));
  background: linear-gradient(0deg, white 0%, rgba(230, 126, 94, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
}
