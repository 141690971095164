@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.about_swim {
  @include sectionPM;
  background-color: #ececec;
}

.about_swim_item {
  @include flexcolcenter;
  padding: 15px;

  .item_icon_wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
  }
}
.item_text {
  text-align: center;
  margin: 10px 0;
  color: #444;
  font-family: $main_font;
  font-size: 22px;
  font-weight: 550;
}
