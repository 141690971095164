@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.blog {
  @include sectionPM;
  .blog_title {
    @include main_title($color: #333);
  }
}

.blog_item {
  padding: 20px 0;
  width: 100%;
  border-bottom: 2px solid #eee;
  .blog_item_img_area {
    height: 300px;
    width: 100%;
    overflow: hidden;

    .blog_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.blog_item_info {
  padding: 15px 0;
  .blog_icon {
    color: #aaa;
    margin-right: 5px;
  }
  .blog_item_info_author {
    font-family: $main_font;
    color: #999;
  }
}
.blog_item_content {
  padding: 10px 0;
  width: 100%;
}

.blog_item_title {
  font-family: $main_font;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  color: #000;
}
.blog_item_text {
  font-family: $main_font;
  color: #888;
  font-size: 17px;
}
.blog_item_footer {
  margin-top: 10px;
  width: 100%;
}
.blog_item_desc_area {
  position: relative;
  z-index: 0;
}
.blog_item_fade {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(230, 126, 94, 0) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 1;
}
