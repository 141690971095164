@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.blog_home {
  padding: 40px 0;
  margin: 30px 0;
}

.card {
  background-color: #fff;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card_img_wrapper {
  height: 250px;
  overflow: hidden;
}

.card_img_wrapper .card_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.card_img_wrapper .card_img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.card_body {
  margin: 10px 0;
  padding: 10px;
}

.card_title {
  font-size: 22px;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: inline-block;
  color: #000;
}

.card_description {
  margin-bottom: 20px;
  font-family: "Source Sans Pro", sans-serif;
  color: #888;
}

.main_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.card_time {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
}

.card_desc_area {
  height: 180px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.card_desc_fade {
  background: -webkit-gradient(linear, left bottom, left top, from(white), to(rgba(230, 126, 94, 0)));
  background: linear-gradient(0deg, white 0%, rgba(230, 126, 94, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
