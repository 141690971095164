@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.about_swim {
  padding: 40px 0;
  margin: 30px 0;
  background-color: #ececec;
}

.about_swim_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px;
}

.about_swim_item .item_icon_wrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
}

.item_text {
  text-align: center;
  margin: 10px 0;
  color: #444;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-weight: 550;
}
