@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.home_gallery {
  @include sectionPM;
}
.home_gallery_item {
  overflow: hidden;
  height: 100%;
  .gallery_img {
    &:hover {
      transform: scale(1.1);
    }
  }
}
.gallery_img {
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.hrzntl_little {
  height: 235px;
}
.home_gallery_title {
  @include main_title($color: #333);
}
.vertical_img {
  height: 485px !important;
}
.horizontal_big {
  height: 485px !important;
}
