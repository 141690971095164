@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.gallery {
  padding: 40px 0;
  margin: 30px 0;
}

.gallery__title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.gallery__item {
  max-height: 250px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gallery_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.gallery_img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
