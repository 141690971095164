 .whatsapp{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    width: 120px;
    height: 120px;
 }

 @media (max-width: 550px) {
    .whatsapp{
        width: 95px;
        height: 95px;
    }
 }