@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.about {
  padding: 40px 0;
  margin: 30px 0;
}

.about .title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.teacher_area {
  padding: 40px 0;
  margin: 30px 0;
}

.subtitle {
  font-size: 35px;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 20px;
}

.description {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 20px;
  color: #555;
  font-size: 17px;
}

.img_area {
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.item {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
}

.img_area {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}

.person_img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.person_img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.content {
  padding: 15px;
}

.name {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

.designation {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #666;
  text-align: center;
}
