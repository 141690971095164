@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.sponsors {
  @include sectionPM;
  background-color: #fbfbfb;
}

.contact__title {
  @include main_title("#fff");
}

.sponsors__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.sponsors__item {
  padding: 5px;
  margin: 5px;
  list-style: none;
}

.sponsors__title {
  font-size: 17px;
  color: #000;
}
