@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.bookform {
  padding: 40px 0;
  margin: 30px 0;
}

.form_slogan {
  width: 100%;
}

.form_slogan .form_slogan_title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 55px;
  font-weight: 550;
  line-height: 1.3;
  max-width: 500px;
  text-align: left;
}

.form_slogan_icon_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form_area {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.form_area .form_area_title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: 550;
  line-height: 1.3;
  text-align: center;
}

.datepicker {
  border: 1px solid #ddd;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
}
