@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.facilities {
  padding: 40px 0;
  margin: 30px 0;
}

.facility_title {
  font-size: 2rem;
  color: #000;
  font-weight: 600;
}

.facility_text {
  font-size: 1.2rem;
  color: #000;
  font-weight: 400;
  margin-top: 2rem;
}
