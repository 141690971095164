@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.about {
  @include sectionPM;

  .title {
    @include main_title($color: #333);
  }
}
.teacher_area {
  @include sectionPM;
}
.subtitle {
  font-size: 35px;
  font-family: $main_font;
  margin-bottom: 20px;
}
.description {
  font-family: $main_font;
  margin-bottom: 20px;
  color: #555;
  font-size: 17px;
}
.img_area {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.item {
  width: 100%;
  height: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  overflow: hidden;

  border-radius: 10px;
}
.img_area {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}
.person_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.content {
  padding: 15px;
}
.name {
  font-family: $main_font;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}
.designation {
  font-family: $main_font;
  font-size: 17px;
  font-weight: 600;
  color: #666;
  text-align: center;
}
