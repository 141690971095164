@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.branches {
  padding: 40px 0;
  margin: 30px 0;
  background-color: #fbfbfb;
}

.contact__title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: "#fff";
  margin-bottom: 40px;
}

.branches__wrapper {
  margin: 20px 0;
  width: 100%;
}

.branches__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.branches__img {
  max-width: 70px;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.branches__title {
  color: #000;
  font-size: 22px;
  margin-top: 15px;
}
