@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.header {
  padding: 20px 0;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 20;
  background-image: url("../../gallery/assets/item-10.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
}

.header .header_top {
  padding: 15px 0;
  width: 100%;
}

.header_top_text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-weight: 550;
  margin: 0;
  color: #fff;
}

.header_top_text .header_top_text_span {
  font-weight: 600;
}

.header_brand {
  width: 150px;
}

.icon_wrapper {
  min-width: 255.5px;
}

.header_icon {
  margin-left: 15px;
  font-size: 25px;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header_icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  color: #FBCB0A;
}

.navbar {
  width: 100%;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar .navbar_inner {
  margin-bottom: 20px;
  padding: 15px 0;
  border-top: 2px solid rgba(187, 187, 187, 0.274);
  border-bottom: 2px solid rgba(187, 187, 187, 0.274);
}

.navbar_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  padding: 15px 0;
}

.navbar_item {
  list-style: none;
  margin: 0 15px;
}

.navbar_item .navbar_link {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 550;
}

.navbar_item .navbar_link:hover {
  color: #FBCB0A;
}

.banner {
  padding: 30px 0;
  height: 100%;
  width: 100%;
}

.carousel_item {
  padding: 15px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: fade_carousel 2s ease-in-out;
          animation: fade_carousel 2s ease-in-out;
  text-align: center;
  min-height: 620px;
}

.carousel_item .carousel_item_title {
  color: #fff;
  font-size: 50px;
  max-width: 700px;
  margin-bottom: 50px;
}

.carousel_item .carousel_item_text {
  color: #ddd;
  font-size: 20px;
  max-width: 700px;
  margin-bottom: 50px;
}

@-webkit-keyframes fade_carousel {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade_carousel {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header_fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel_icon {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.sticky_nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.scrolled_inner {
  padding: 10px 0;
}

.scrolled_header::after {
  content: "";
  display: block;
  height: 89.5px;
}

.draw_list {
  margin: 10px 0;
}

.draw_list .draw_item {
  list-style: none;
  margin-bottom: 12px;
}

.draw_list .draw_item .draw_link {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 550;
}

.scrolled_hamburger {
  color: #319795 !important;
}

.scrolled_icon {
  color: #319795 !important;
}

.scrolled_mobile {
  background: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.after__scroll {
  margin-top: 100px !important;
}

.hamburger_btn {
  display: none !important;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media screen and (max-width: 767.99px) {
  .navbar {
    display: none !important;
  }
  .header_top_text {
    font-size: 13px !important;
  }
  .header_top_left_area {
    display: none !important;
  }
  .hamburger_btn {
    display: block !important;
  }
}

@media screen and (max-width: 549.99px) {
  .header_icon {
    color: #000 !important;
  }
  .header_top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }
  .banner {
    padding-top: 100px !important;
  }
  .header_top_left_area {
    display: none !important;
  }
  .header_brand {
    margin-left: 15px;
    width: 100px !important;
  }
}
