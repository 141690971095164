@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.footer {
  padding: 50px 0;
  width: 100%;
  height: 100%;
  background: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer_top {
  width: 100%;
  padding-bottom: 20px;
}
.footer_top_list {
  @include flexrowcenter;
  margin: 0;
  padding: 10px 0;

  .footer_top_item {
    margin: 0 15px;
    list-style: none;

    .footer_top_link {
      color: #fff;
      font-family: $main_font;
      font-size: 22px;

      &:hover {
        color: $main_yellow;
      }
    }
  }
}
.left_list {
  width: 100%;

  .left_item {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .left_icon {
      min-width: 35px;
      height: 35px;
      border-radius: 50%;
      padding: 5px;
      border: 1px solid #ddd;
      margin-right: 10px;
      font-size: 22px;
      color: #fff;
    }
    .left_text {
      font-family: $main_font;
      color: #fff;
    }
  }
}
.footer_logo {
  width: 200px;
  height: 150px;
}

.footer_social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .footer_social_icon {
    font-size: 30px;
    margin: 0 10px;
    color: #fff;
  }
}

@media screen and (max-width:599px) {
    .footer_top{
        display: none !important;
    }
}