@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.home_gallery {
  padding: 40px 0;
  margin: 30px 0;
}

.home_gallery_item {
  overflow: hidden;
  height: 100%;
}

.home_gallery_item .gallery_img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.gallery_img {
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.hrzntl_little {
  height: 235px;
}

.home_gallery_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.vertical_img {
  height: 485px !important;
}

.horizontal_big {
  height: 485px !important;
}
