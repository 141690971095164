@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.branches {
  @include sectionPM;
  background-color: #fbfbfb;
}
.contact__title {
  @include main_title("#fff");
}
.branches__wrapper {
  margin: 20px 0;
  width: 100%;
}
.branches__item {
  @include flexcolcenter;
}
.branches__img {
  max-width: 70px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.branches__title {
  color: #000;
  font-size: 22px;
  margin-top: 15px;
}
