@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.subscribe {
  padding: 40px 0;
  margin: 30px 0;
  position: relative;
  z-index: 1;
  background: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.subscribe__title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  margin-bottom: 40px;
}

.subscribe__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
}

.bg_fade {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.sub_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
