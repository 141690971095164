@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.bookform {
  @include sectionPM;
}
.form_slogan {
  width: 100%;
  .form_slogan_title {
    font-family: $main_font;
    font-size: 55px;
    font-weight: 550;
    line-height: 1.3;
    max-width: 500px;
    text-align: left;
  }
}
.form_slogan_icon_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form_area {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  .form_area_title {
    font-family: $main_font;
    font-size: 24px;
    font-weight: 550;
    line-height: 1.3;
    text-align: center;
  }
}

.datepicker{
  border : 1px solid #ddd;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
}