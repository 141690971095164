@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.blog_home {
  @include sectionPM;
}

.card {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card_img_wrapper {
  height: 250px;
  overflow: hidden;
  .card_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.card_body {
  margin: 10px 0;
  padding: 10px;
}
.card_title {
  font-size: 22px;
  font-family: $main_font;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: inline-block;
  color: #000;
}
.card_description {
  margin-bottom: 20px;
  font-family: $main_font;
  color: #888;
}
.main_title {
  @include main_title($color: #333);
}
.card_time{
  font-family: $main_font;
  font-size: 15px;
}

.card_desc_area{
  height: 180px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.card_desc_fade{
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(230,126,94,0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}