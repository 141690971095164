@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";
.header {
  padding: 20px 0;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 20;
  background-image: url("../../gallery/assets/item-10.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  .header_top {
    padding: 15px 0;

    width: 100%;
  }
}

.header_top_text {
  font-family: $main_font;
  font-size: 15px;
  font-weight: 550;
  margin: 0;
  color: #fff;
  .header_top_text_span {
    font-weight: 600;
  }
}
.header_brand {
  width: 150px;
}
.icon_wrapper {
  min-width: 255.5px;
}
.header_icon {
  margin-left: 15px;
  font-size: 25px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
    color: $main_yellow;
  } 
}

.navbar {
  width: 100%;
  display: block;
  transition: all 0.3s ease-in-out;
  .navbar_inner {
    margin-bottom: 20px;
    padding: 15px 0;
    border-top: 2px solid rgba(187, 187, 187, 0.274);
    border-bottom: 2px solid rgba(187, 187, 187, 0.274);
  }
}
.navbar_list {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 15px 0;
}
.navbar_item {
  list-style: none;
  margin: 0 15px;

  .navbar_link {
    color: #fff;
    font-family: $main_font;
    font-size: 17px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 550;

    &:hover {
      color: $main_yellow;
    }
  }
}

.banner {
  padding: 30px 0;
  height: 100%;
  width: 100%;
}
.carousel_item {
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade_carousel 2s ease-in-out;
  text-align: center;
  min-height: 620px;
  .carousel_item_title {
    color: #fff;
    font-size: 50px;
    max-width: 700px;
    margin-bottom: 50px;
  }
  .carousel_item_text {
    color: #ddd;
    font-size: 20px;
    max-width: 700px;
    margin-bottom: 50px;
  }
}

@keyframes fade_carousel {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header_fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}
.carousel_icon {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.sticky_nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.scrolled_inner {
  padding: 10px 0;
}
.scrolled_header {
  &::after {
    content: "";
    display: block;
    height: 89.5px;
  }
}

.draw_list{
  margin: 10px 0;

  .draw_item{
    list-style: none;
    margin-bottom: 12px;

    .draw_link{
      font-size: 20px;
      text-transform: uppercase;
      font-family: $main_font;
      font-weight: 550;
    }
  }
}
.scrolled_hamburger{   
  color: #319795 !important;
}
.scrolled_icon{
  color: #319795 !important;
}
.scrolled_mobile {
  background: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.after__scroll {
  margin-top: 100px !important;
}
.hamburger_btn{
  display: none !important;
  transition: all .3s ease-in-out;
}
@media screen and (max-width: 767.99px) {
  .navbar{
    display: none !important;
  }
  .header_top_text {
    font-size: 13px !important;
  }
  .header_top_left_area{
    display: none !important;
  }
  .hamburger_btn{
    display: block !important;
  }
}
@media screen and (max-width: 549.99px) {
  .header_icon{
      color: #000 !important;
  }

  .header_top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }
  .banner {
    padding-top: 100px !important;
  }
  .header_top_left_area {
    display: none !important;
  }
  .header_brand {
    margin-left: 15px;
    width: 100px !important;
  }
}
