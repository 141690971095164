@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.admin_login {
  width: 100%;
  height: 100vh;
  @include flexcolcenter;
}

.admin_login_area {
  max-width: 550px;
  margin: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;

  .admin_login_content {
    padding: 15px;

    .admin_login_content_title {
      text-align: center;
      font-size: 22px;
      margin: 10px 0;
      font-family: $main_font;
    }
  }
}
.admin_login_img {
  margin: auto;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.admin_header {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 0;
  width: 100%;
}
.admin_header_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin_brand {
  width: 50px;
  text-align: center;
}

.admin_header_nav_list {
  @include flexrowcenter;

  .admin_header_nav_list_item {
    margin: 0 15px;
    list-style: none;

    .admin_header_nav_link {
      font-family: $main_font;
      font-size: 18px;
      text-transform: uppercase;
      &:hover {
        color: $main_yellow;
      }
    }
  }
}
.admin_mobile_icon {
  display: none;
}
.draw_list {
  margin: 10px 0;
  .draw_item {
    margin-bottom: 15px;
    list-style: none;
    .draw_link {
      font-size: 18px;

      &:hover {
        color: $main_yellow;
      }
    }
  }
}

.book_area {
  @include sectionPM;
  .book_area_title {
    @include main_title($color: #333);
  }
}

.admin_subscribe {
  @include sectionPM;
  .admin_subscribe_title {
    @include main_title($color: #333);
  }
}

.admin_messages {
  @include sectionPM;
  .admin_messages_title {
    @include main_title($color: #333);
  }
}

.admin_blog {
  @include sectionPM;
  .admin_blog_title {
    @include main_title($color: #333);
  }
}
.admin_blog_list {
  margin: 25px 0;
  padding: 15px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}
.admin_blog_item {
  width: 100%;
  margin-bottom: 25px;
  border-bottom: 2px solid #eee;
  padding: 20px 0;
}
.admin_blog_info {
  width: 100%;
}
.blog_list_item {
  list-style: none;
  margin-bottom: 15px;
}
.blog_list_item_title {
  font-size: 18px;
  font-family: $main_font;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 600;
}
.blog_list_item_value{
  font-size: 18px;
  font-family: $main_font;
}
.admin_blog_image {
  width: 250px;
  text-align: center;
  margin: auto;
}
.add_blog {
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 700px;
  margin: 25px auto;
}

.admin_blog_footer{
  width: 100%;
  margin-top: 20px;
}
.blog_time{
  font-size: 18px;
  font-family: $main_font;
  color: green;
  font-weight: 600;
}
@media screen and (max-width: 991.99px) {
  .admin_mobile_icon {
    display: block !important;
  }
  .admin_header_nav_list {
    display: none !important;
  }
}
