@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.gallery {
  @include sectionPM;
}

.gallery__title {
  @include main_title($color: #333);
}
.gallery__item{
    max-height: 250px;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gallery_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
}