@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.subscribe {
  @include sectionPM;
  position: relative;
  z-index: 1;
  background: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.subscribe__title {
  @include main_title($color: #fff);
}
.subscribe__form {
  @include flexrowcenter;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
}
.bg_fade {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.sub_wrapper {
  @include flexrowcenter;
}
