@import "../../../SCSS/mixins.scss";
@import "../../../SCSS/variables.scss";
.facilities{
    @include sectionPM
} 

.facility_title{
    font-size: 2rem;
    color: #000;
    font-weight: 600;
}

.facility_text{
    font-size: 1.2rem;
    color: #000;
    font-weight: 400;
    margin-top: 2rem;
}