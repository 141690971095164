@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.admin_login {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin_login_area {
  max-width: 550px;
  margin: auto;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
}

.admin_login_area .admin_login_content {
  padding: 15px;
}

.admin_login_area .admin_login_content .admin_login_content_title {
  text-align: center;
  font-size: 22px;
  margin: 10px 0;
  font-family: "Source Sans Pro", sans-serif;
}

.admin_login_img {
  margin: auto;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  padding: 10px;
  background-color: #fff;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.admin_header {
  background-color: #fff;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 0;
  width: 100%;
}

.admin_header_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin_brand {
  width: 50px;
  text-align: center;
}

.admin_header_nav_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin_header_nav_list .admin_header_nav_list_item {
  margin: 0 15px;
  list-style: none;
}

.admin_header_nav_list .admin_header_nav_list_item .admin_header_nav_link {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}

.admin_header_nav_list .admin_header_nav_list_item .admin_header_nav_link:hover {
  color: #FBCB0A;
}

.admin_mobile_icon {
  display: none;
}

.draw_list {
  margin: 10px 0;
}

.draw_list .draw_item {
  margin-bottom: 15px;
  list-style: none;
}

.draw_list .draw_item .draw_link {
  font-size: 18px;
}

.draw_list .draw_item .draw_link:hover {
  color: #FBCB0A;
}

.book_area {
  padding: 40px 0;
  margin: 30px 0;
}

.book_area .book_area_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.admin_subscribe {
  padding: 40px 0;
  margin: 30px 0;
}

.admin_subscribe .admin_subscribe_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.admin_messages {
  padding: 40px 0;
  margin: 30px 0;
}

.admin_messages .admin_messages_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.admin_blog {
  padding: 40px 0;
  margin: 30px 0;
}

.admin_blog .admin_blog_title {
  text-align: center;
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #333;
  margin-bottom: 40px;
}

.admin_blog_list {
  margin: 25px 0;
  padding: 15px;
  background-color: #fff;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}

.admin_blog_item {
  width: 100%;
  margin-bottom: 25px;
  border-bottom: 2px solid #eee;
  padding: 20px 0;
}

.admin_blog_info {
  width: 100%;
}

.blog_list_item {
  list-style: none;
  margin-bottom: 15px;
}

.blog_list_item_title {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 600;
}

.blog_list_item_value {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
}

.admin_blog_image {
  width: 250px;
  text-align: center;
  margin: auto;
}

.add_blog {
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 700px;
  margin: 25px auto;
}

.admin_blog_footer {
  width: 100%;
  margin-top: 20px;
}

.blog_time {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  color: green;
  font-weight: 600;
}

@media screen and (max-width: 991.99px) {
  .admin_mobile_icon {
    display: block !important;
  }
  .admin_header_nav_list {
    display: none !important;
  }
}
