@import "../../../SCSS/variables.scss";
@import "../../../SCSS/mixins.scss";

.contact {
  @include sectionPM;

  .contact__title {
    @include main_title($color: #333);
  }
}

.contact__content {
  padding: 30px 0;
}
.contact__list {
  width: 100%;
  height: 100%;

  .contact__item {
    list-style: none;
    margin-bottom: 20px;
    padding: 5px 0;
  }
}
.item__link {
  text-align: center;
  display: block;
}
.item__icon {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #4b7be5;
}
.item__text {
  font-family: $main_font;
  font-size: 20px;
}
