@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.footer {
  padding: 50px 0;
  width: 100%;
  height: 100%;
  background: url("../assets/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer_top {
  width: 100%;
  padding-bottom: 20px;
}

.footer_top_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 10px 0;
}

.footer_top_list .footer_top_item {
  margin: 0 15px;
  list-style: none;
}

.footer_top_list .footer_top_item .footer_top_link {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
}

.footer_top_list .footer_top_item .footer_top_link:hover {
  color: #FBCB0A;
}

.left_list {
  width: 100%;
}

.left_list .left_item {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 10px;
}

.left_list .left_item .left_icon {
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid #ddd;
  margin-right: 10px;
  font-size: 22px;
  color: #fff;
}

.left_list .left_item .left_text {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
}

.footer_logo {
  width: 200px;
  height: 150px;
}

.footer_social {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}

.footer_social .footer_social_icon {
  font-size: 30px;
  margin: 0 10px;
  color: #fff;
}

@media screen and (max-width: 599px) {
  .footer_top {
    display: none !important;
  }
}
